import React from 'react';

import { WidgetProps } from '../core/types/WidgetProps';

export enum HawkWrapper {
  VULTURE_REVEAL_CONTEXT = 'VultureRevealContext',
  HAWK_WIDGET_ADVANCED = 'HawkWidgetAdvanced',
  HAWK_WIDGET_STANDARD = 'HawkWidgetStandard',
}

export const withHawkWidget = <P extends WidgetProps>(
  Component: React.FC<P>,
  wrapper: HawkWrapper,
): React.FC<P> => {
  const componentFn: React.FC<P> = (props) => {
    const { genericSharedComponents, children } = props;
    const HawkWidgetWrapper = genericSharedComponents[wrapper];
    return (
      // TODO: Straight up types between HawkWidgetAdvanced and HawkWidgetStandard
      // eslint-disable-next-line
      <HawkWidgetWrapper {...(props as any)}>
        <Component {...props}>{children}</Component>
      </HawkWidgetWrapper>
    );
  };

  componentFn.getInitialiserProps = Component.getInitialiserProps;

  return componentFn;
};
