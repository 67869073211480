export const getAllVoucherOffersCount = (counts: Record<string, number>): number => {
  return (
    counts?.vouchers +
    counts?.unique_codes +
    counts?.offers +
    counts?.deals +
    counts?.offer_deals +
    counts?.rewards
  );
};
