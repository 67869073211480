import React from 'react';

import { getClickType } from '../../../../../sharedModules/getClickType';
import buildUrl from '../../../../../utils/buildUrl';
import type { Deal } from '../../../../types/Deal';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../../TrackedLink/TrackedLink';

interface VultureButtonAffiliateLinkWrapperProps {
  setPopupDeal: (deal: Deal) => void;
  setSeen: (deal: Deal) => void;
  deal: Deal;
  elementType: string;
  children: React.ReactNode;
  stringOfSeen: string;
}

export const VultureButtonAffiliateLinkWrapper: React.FC<VultureButtonAffiliateLinkWrapperProps> =
  ({ setPopupDeal, setSeen, deal, elementType, children, stringOfSeen }) => {
    const {
      articleUrl,
      genericSharedComponents: { AffiliateLink },
    } = useHawkWidgetContext();

    const handleLinkClick = setPopupDeal
      ? (): void => {
          const futurePageUrl =
            document.location.href &&
            buildUrl(document.location.href.split(/[?|#]+/)[0], {
              visibleMatchId: deal?.match_id,
              seenMatchId: stringOfSeen,
              '#': document.location.href.split(/#+/)[1],
            });
          window.open(futurePageUrl);
          setPopupDeal(deal);
          setSeen(deal);
        }
      : (): null => null;

    return (
      <AffiliateLink
        deal={deal}
        className={elementType === 'button' ? 'vulture-button' : 'container'}
        linkClick={handleLinkClick}
        customTarget={TargetEnum.SELF}
        clickType={getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })}
      >
        {children}
      </AffiliateLink>
    );
  };
